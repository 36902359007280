import { Environment } from 'environments/environment.model';
import { version } from 'environments/../../package.json';

export const environment = <Environment>{
    production: false,
    app: {
        version: version,
        env: 'uva-tst'
    },
    organization: 'uva',
    analyticsEndpoint: '',
    artifactLocation: 'https://appstore.uva.tst.icts.education/assets/apps/'

};
